import React from "react";
import { Row, Col, Container, Form, Alert, Badge, Button, Card, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';

export default class AnyList extends React.Component {
  constructor(props) {
    super(props);

    var initial_seed_list = [];
    var show_seed_ui = [];
    this.seedModals = [];
    this.setSeedModal = [];

    for(let i = 0; i < this.n_seed(); i++) {
      initial_seed_list.push(null);
      show_seed_ui.push(false);
      this.seedModals.push(null);
      this.setSeedModal.push(
        element => {
          // console.log("calling setSeedModal " + i);
          this.seedModals[i] = element;
        }
      );
    }

    this.state = {
      list_id: this.list_id(),
      imp_id: null,
      variant: null,

      seed_list: initial_seed_list,
      show_seed_ui: show_seed_ui,

      recs: [],
      watchlist: [],

      // statuses: idle, awaiting_recs, awaiting_metadata, error
      status: 'ok',
      error_message: null,

      // id of the item that we want to show the item details modal for
      show_modal_id: null,
    };

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  }

  list_id() {
    if(this.props.list_id)
      return this.props.list_id;
    if(this.props.match && this.props.match.params && this.props.match.params.list_id)
      return this.props.match.params.list_id;
    return '';
  }

  list_created() {
    return (this.list_id() != '');
  }

  n_seed() {
    return 3;
  }

  n_seed_list() {
    var ct = 0;
    for(let i = 0; i < Math.min(this.state.seed_list.length, this.n_seed()); i++) {
      if(this.state.seed_list[i] !== null)
        ct += 1;
    }
    return ct;
  }

  // if any of the seed modals are active, return the one that is, else return null
  get_active_seed_modal() {
    for(let i = 0; i < this.n_seed(); i++)
      if(this.state.show_seed_ui[i]) {
        return this.seedModals[i];
      }
    return null;
  }

  rec_type() {
    return this.props.rec_type || 'any';
  }

  componentDidMount() {
    // figure out keypress later...
    document.addEventListener("keypress", this.keyStrokeListener.bind(this), false);
    if(this.list_id() != '') {
      this.fetch_list();
      document.title = 'SmartList (' + this.list_id() + ')';
    }
  }

  // initially, navbar will be null, at some point, the ref will be set and flow in here...
  componentDidUpdate(prevProps) {
    if(this.props.current_user && !prevProps.current_user)
      lib.fetch_watchlist(
        this.props.navbar,
        'smartlist',
        (rd) => {
          this.setState({
            watchlist: rd.watchlist.rec_items,
          });
        }
      );
  }

  keyStrokeListener(e) {
    if(e.charCode == 13) {
      var tmp = this.get_active_seed_modal();
      tmp.seedSearchButton.button.click();
    }
  }

  renderStatus() {
    if(this.state.status == 'generating_list' || this.state.status == 'fetching_seeds')
      return (
        <Row>
          <Col>
            <Row>
              <Col className={'centered'}>
                <i className="fa fa-spinner fa-pulse fa-spin fa-3x" />
              </Col>
            </Row>
            <Row>
              <Col className={'centered'}>
                Our minions are working...
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if(this.state.status == 'error')
      return (
        <Row>
          <Col className='centered'>
            <Alert variant="danger">
              Something went wrong: {this.state.error_message}
            </Alert>
          </Col>
        </Row>
      );
    return null;
  }

  renderSeed(item, index) {
    if(item === null) {
      // add seed UI
      return(
        <Col className="centered" xs={12} md={3} key={index}>
          <Card
            className="movie-card"
            onClick={() => {
              this.setState({
                  show_seed_ui: update(
                    this.state.show_seed_ui, {[index]: {$set: true}}
                  ),
                },
                () => {this.seedModals[index].focus();}
              );
            }}
          >
            <Card.Body>
              <Card.Title className="centered">
                Add Example
              </Card.Title>
              <div className="centered add-item">
                <i className="fa fa-plus fa-3x" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    }
    return (
      <this.props.Item
        metadata={item}
        imp_id={this.state.imp_id}
        index={index}
        onClick={
          this.list_created() ?
          () => {
            // follow the link but don't log a click...?
            // lib.open_url_in_new_tab(lib.imdb_url(item.imdbID));
            // open the item details modal
            this.setState({
              show_modal_id: item.imdbID,
            });
          } : () => {
            this.setState({
              show_seed_ui: update(
                this.state.show_seed_ui, {[index]: {$set: true}}
              ),
            });
          }
        }
        is_result={false}
        add_action={(s, f) => {
          lib.add_to_watchlist(
            this.props.navbar,
            item,
            (response) => {
              this.setState({
                watchlist: response.data.watchlist.rec_items,
              })
            },
            s,
            f
          );
        }}
        item_in_watchlist={this.item_in_watchlist(item)}
        key={item.imdbID}

        show_modal={this.state.show_modal_id == item.imdbID}
        close_modal={ () => {
          this.setState({
              show_modal_id: null,
            },
            () => {
              this.setState({
                  show_modal_id: null,
                },
              );
            }
          );
        }}
        open_modal={ () => {
          this.setState({
            show_modal_id: item.imdbID,
          });
        }}
      />
    );
  }

  renderSmartListInterface() {
    return (
      <Container>
        <Row className="spacer" />
        {
          // this renders the search modal
          this.state.seed_list.map((item, index) =>
            <this.props.itemSeedInterface
              show={this.state.show_seed_ui[index]}
              close={() => {
                this.setState({
                  show_seed_ui: update(
                    this.state.show_seed_ui, {[index]: {$set: false}}
                  ),
                })
              }}
              key={index}
              ref={this.setSeedModal[index]}
              add_seed={
                (s) => {
                  this.setState({
                    seed_list: update(
                      this.state.seed_list, {[index]: {$set: s}}
                    )
                  });
                }
              }
            />
          )
        }
        <Row className="justify-content-space-between">
        {
          // this renders the seed thumbnails, clicking on this opens the search modal
          this.state.seed_list.map((item, index) => this.renderSeed(item, index))
        }
        {
          (this.list_id() == '') ?
          <Col className="centered" xs={12} md={3}>
            <div className="m-t-1-rem">
              <SmartButton
                disabled={this.n_seed_list() < this.n_seed()}
                action={ (s, f) => {this.generate_list(s, f);} }
              >
                Generated SmartList
                {
                  (this.n_seed_list() < this.n_seed()) ?
                  " (Add " + (this.n_seed() - this.n_seed_list()) + " more items)" : null
                }
              </SmartButton>
            </div>
          </Col> : null
        }
        </Row>
        <Row className="spacer" />
      </Container>
    );
  }

  render() {
    return (
      <Col sm={10} className="h-100 col-centered" id="main-content">
      <Container fluid className="min-h-100 flex-col">
        <Row className="spacer" />
        <Row>
          <Col xs={12} md={2} className="centered capitalize">
            <h3>
              {this.rec_type()}
            </h3>
          </Col>
          <Col xs={12} md={10}>
            <h5>
              How it works
            </h5>
            <ul>
              <li>
                Add 3 examples of {this.rec_type()} that you like
              </li>
              <li>
                The AI will generate a list of similar items based on your examples
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="spacer" />
        {
          this.renderSmartListInterface()
        }
        {
          this.renderStatus()
        }
        <Row className='justify-content-space-around'>
        {
          // render recs
          this.state.recs.map((item, index) =>
            <this.props.Item
              metadata={item}
              imp_id={this.state.imp_id}
              index={this.index}
              add_action={(s, f) => {
                lib.add_to_watchlist(
                  this.props.navbar,
                  item,
                  (response) => {
                    this.setState({
                      watchlist: response.data.watchlist.rec_items,
                    })
                  },
                  s,
                  f
                );
              }}
              item_in_watchlist={this.item_in_watchlist(item)}
              key={item.imdbID}

              show_modal={this.state.show_modal_id == item.imdbID}
              close_modal={ () => {
                this.setState({
                    show_modal_id: null,
                  },
                  () => {
                    this.setState({
                        show_modal_id: null,
                      },
                    );
                  }
                );
              }}
              open_modal={ () => {
                this.setState({
                  show_modal_id: item.imdbID,
                });
              }}
            />
          )
        }
        </Row>
      </Container>

      </Col>
    );
  }

  item_in_watchlist(item) {
    return this.state.watchlist
      .map(x => this.props.metadata2uid(x))
      .indexOf(this.props.metadata2uid(item)) > -1;
  }

  async fetch_list() {
    console.log("fetching list...");
    console.log(this.state.list_id);

    await this.setState({
      recs: [],
      status: 'fetching_seeds',
      error_message: null,
    });

    await axios
      .post(
        lib.get_proxy_api_endpoint('fetch_smart_list_seeds'),
        {
          list_id: this.state.list_id,
        },
      )
      .then((response) => {
        console.log(response.data);

        this.setState({
          status: 'generating_list',
          error_message: null,
          seed_list: response.data.seeds,
        });

        this.generate_list();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
            status: 'error',
            error_message: lib.stringify_error(err),
          },
        );
      });
  }

  async generate_list(onSuccess = () => {}, onFail = () => {}) {
    // create a request to BE, then reroute, or... reroute then generate?
    // two step: first, create a list object and reroute to that list object's id. then on load, generate contents of list
    if(this.list_id() == '') {
      await axios
        .post(
          lib.get_proxy_api_endpoint('create_smart_list'),
          {
            rec_type: this.rec_type(),
            seeds: this.state.seed_list,
          },
        )
        .then((response) => {
          console.log(response.data);
          lib.open_url_in_this_tab(lib.get_url(this.rec_type() + '-list', response.data));
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.setState({
              status: 'error',
              error_message: lib.stringify_error(err),
            },
          );
          onFail();
        });
    }
    else {
      await axios
        .post(
          lib.get_proxy_api_endpoint('fetch_smart_list'),
          {
            list_id: this.list_id(),
          },
        )
        .then((response) => {
          console.log(response.data);
          this.setState({
            recs: response.data['recs'],
            imp_id: response.data['imp_id'],
            status: 'ok',
          });
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.setState({
              status: 'error',
              error_message: lib.stringify_error(err),
            },
          );
          onFail();
        });
    }
  }

  // async add_to_watchlist(item, onSuccess, onFail) {
  //   if(!this.props.navbar.user_is_logged_in()) {
  //     this.props.navbar.open_signup_modal('signup');
  //     onSuccess();
  //   }
  //   else
  //     axios
  //       .post(
  //         lib.get_proxy_api_endpoint('add_to_watchlist'),
  //         {
  //           target: item,
  //           rec_type: this.rec_type(),
  //         },
  //       )
  //       .then((response) => {
  //         console.log("added item to watch list");
  //         this.setState({
  //           watchlist: response.data.watchlist.rec_items,
  //         })
  //         onSuccess();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         console.log(err.response);
  //         onFail();
  //       });
  // }
}