import React from "react";
import { Row, Col, Container, Modal, Button, Form, Alert, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import AnyList from './anylist';
import MovieCard from './movieCard';

export class MoviesSeedInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      query: '',
      status: 'ok',
      error_message: null,
    }

    this.seedSearchButton = null;
    this.setSeedSearchButton = element => { this.seedSearchButton = element; };
    this.seedSearchTextField = null;
    this.setSeedSearchTextField = element => { this.seedSearchTextField = element; };
  }

  focus() {
    this.seedSearchTextField.focus();
  }

  renderStatus() {
    if(this.state.status == 'ok')
      return null;
    if(this.state.status == 'searching')
      return (
        <Row className="m-t-1-rem">
          <Col>
            <Row>
              <Col className={'centered'}>
                <i className="fa fa-spinner fa-pulse fa-spin fa-3x" />
              </Col>
            </Row>
            <Row>
              <Col className={'centered'}>
                "Our minions are working..."
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if(this.state.status == 'error')
      return (
        <Row className="m-t-1-rem">
          <Col className="centered">
            <Alert variant="danger">
              Something went wrong: {this.state.error_message}
            </Alert>
          </Col>
        </Row>
      );
    console.error("Error: unknown mode " + this.state.status);
    return null;
  }

  render() {
    // console.log(this.state.result === null);
    return (
      <Modal show={this.props.show} onHide={this.props.close} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Add A Movie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={10}>
              <Form.Control
                type="text"
                placeholder='Title or IMDb ID (e.g., "Good Will Hunting", "tt0119217")'
                onChange={e => this.setState({query: e.target.value})}
                ref={this.setSeedSearchTextField}
              />
            </Col>
            <Col xs={2} >
              <SmartButton
                action={
                  (s, f) => { this.do_seed_search(s, f); }
                }
                ref={this.setSeedSearchButton}
              >
                Go
              </SmartButton>
            </Col>
          </Row>
          { this.renderStatus() }
          <Row>
            <Col className="centered">
              {
                (this.state.result === null) ? null :
                <MovieCard
                  metadata={this.state.result}
                  imp_id={null}
                  index={null}
                  onClick={() => {
                    this.props.add_seed(this.state.result);
                    this.props.close();
                  }}
                />
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.close}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async do_seed_search(onSuccess, onFail) {
    await this.setState({
      status: 'searching',
    });
    axios
      .post(
        lib.get_proxy_api_endpoint('movie_metadata'),
        {
          movie_title: this.state.query,
        },
      )
      .then((response) => {
        console.log(response.data);
        if(response.data['Response'] == "False") {
          this.setState({
              result: null,
              status: 'error',
              error_message: 'No results, try a different query string'
            },
          );
          onFail();
        }
        else {
          this.setState({
              result: response.data,
              status: 'ok',
            },
            () => {
            }
          );
          onSuccess();
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
            status: 'error',
            error_message: lib.stringify_error(err),
          },
        );
        onFail();
      });
  }
};

export default class MoviesList extends React.Component {
  constructor(props) {
    super(props);
  }

  list_id() {
    if(this.props.list_id)
      return this.props.list_id;
    if(this.props.match && this.props.match.params && this.props.match.params.list_id)
      return this.props.match.params.list_id;
    return '';
  }

  render() {
    return (
      <AnyList
        rec_type = 'movies'
        Item = {MovieCard}
        itemSeedInterface = {MoviesSeedInterface}
        list_id = {this.list_id()}
        navbar = {this.props.navbar}
        current_user = {this.props.current_user}
        metadata2uid = {(m) => m['imdbID']}
      />
    );
  }
};