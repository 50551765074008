import React from "react";
import { Row, Col, Button, Navbar, Nav, NavDropdown, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SignupModal from "./signup";

export default class Gpt3Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_signup_modal: false,
      signup_modal_mode: 'signup',
    };

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    var nav_links = [
      'home',
      'movies',
      'books',
      'any',
      'movies-list',
      'about',
      'blog',
    ];
  }

  user_is_logged_in() {
    if(this.props.current_user === null)
      return false;
    if(!('id' in this.props.current_user) || (this.props.current_user['id'] === null))
      return false;
    return true;
  }

  get_user_name() {
    if(this.props.current_user === null || !('display_username' in this.props.current_user))
      return null;
    return this.props.current_user['display_username'];
  }

  componentDidMount() {
    this.fetch_current_user();
  }

  open_signup_modal(mode) {
    if(!(mode == 'signup' || mode == 'login'))
      mode = 'signup';
    this.setState({
      show_signup_modal: true,
      signup_modal_mode: mode,
    });
  }

  close_signup_modal() {
    this.setState({
      show_signup_modal: false,
    });
  }

  modal_is_open() {
    return this.state.show_signup_modal;
  }

  navbar_click_func(destination) {
    return (e) => {
      e.preventDefault();
      lib.log_click(null, 'n', null, destination);
      if(e.ctrlKey)
        lib.open_url_in_new_tab(lib.get_url(destination));
      else
        lib.open_url_in_this_tab(lib.get_url(destination));
    }
  }

  render() {

    // keep old nav for now as smart lists aren't ready yet
    return (
      <Row>
        <Navbar bg="light" expand="md" className='w-100'>
          <Navbar.Brand
            href="/"
            onClick={this.navbar_click_func('home')}
          >
            {lib.get_domain()}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="gpt3recs-navbar-collapse" />
          <Navbar.Collapse id="gpt3recs-navbar-collapse">
            <Nav className="mr-auto">
              <Nav.Link
                href="/recs/movies/"
                onClick={this.navbar_click_func('movies')}
              >
                Movies
              </Nav.Link>
              <Nav.Link
                href="/recs/books/"
                onClick={this.navbar_click_func('books')}
              >
                Books
              </Nav.Link>
            </Nav>
            <Nav>
              {
                (this.user_is_logged_in()) ? null :
                <Nav.Item>
                  <Button
                    onClick = {() => { this.open_signup_modal('signup'); }}
                  >
                    Sign Up
                  </Button>
                </Nav.Item>
              }
              {
                (this.user_is_logged_in()) ?
                <NavDropdown title={this.get_user_name()}>
                  <NavDropdown.Item href="/recs/watchlist/">Watch List</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => {this.log_out_user()}}>Log Out</NavDropdown.Item>
                </NavDropdown> :
                <Nav.Link
                  onClick = {() => { this.open_signup_modal('login'); }}
                >
                  Log In
                </Nav.Link>
              }
              <Nav.Link
                href="/recs/about/"
                className="border-left"
                onClick={this.navbar_click_func('about')}
              >
                About
              </Nav.Link>
              <Nav.Link
                href="/blog/"
                onClick={ (e) => {
                  e.preventDefault();
                  lib.log_click(null, 'n', null, 'blog');
                  if(e.ctrlKey)
                    lib.open_url_in_new_tab('/blog/');
                  else
                    lib.open_url_in_this_tab('/blog/');
                }}
              >
                Blog
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <SignupModal
          show = {this.state.show_signup_modal}
          close = {() => { this.close_signup_modal(); }}
          mode = {this.state.signup_modal_mode}
          fetch_current_user = { () => {this.fetch_current_user();} }
          sign_in_action = {this.props.sign_in_action}
        />
      </Row>
    );
  }

  // navbar is in charge of keeping track of logged in status
  async fetch_current_user() {
    await axios
      .get(
        lib.get_accounts_api_endpoint('current_user'),
      )
      .then((response) => {
        console.log(response.data);
        this.props.handle_fetch_current_user(response.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  async log_out_user() {
    await axios
      .get(
        lib.get_accounts_api_endpoint('log_out'),
      )
      .then((response) => {
        console.log(response.data);
        this.fetch_current_user();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }
}