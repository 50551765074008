import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

import * as lib from './lib';
import Landing from './components/landing';
import Search from './components/search';
import WatchList from './components/watchlist';
import MoviesList from './components/movieslist';
import BooksList from './components/bookslist';
import AnyList from './components/anylist';
import About from './components/about';
import Contact from './components/contact';
import Gpt3Navbar from "./components/navbar";
import BrowseQueries from "./components/browseQueries";

class GPT3Recs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: null,
      current_user: null,
    };

    // set a ref to navbar and delegate user info management to it
    this.setNavbar = element => {
      this.setState({
        navbar: element,
      });
    }
    this.search_ref = React.createRef();
  }

  render() {
    // debug...
    console.log(lib.get_backwards_compatible_public_url());
    return (      
       <BrowserRouter>
        <Container fluid className="min-h-100 flex-col">
          <Gpt3Navbar
            ref={this.setNavbar}
            current_user={this.state.current_user}
            handle_fetch_current_user={(u) => {this.setState({current_user: u});}}
            sign_in_action={
              () => {
                if(this.search_ref.current) {
                  console.log("refreshing recs");
                  if(this.search_ref.current.specific_search)
                    // this.search_ref.current.specific_search.current.fetch_recs();
                    this.search_ref.current.fetch_recs();
                }
                else
                  console.log("nothing to do");
              }
            }
          />
          <Row className="h-100 flex-grow-1">
            <Switch>
              <Route path={process.env.PUBLIC_URL + "/"} component={Landing} exact/>
              <Route
                path={process.env.PUBLIC_URL + "/:rec_type(movies|books|any)/:query"}
                render={(props) =>
                  <Search
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    ref={this.search_ref}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/:rec_type(movies|books|any)/"}
                render={(props) =>
                  <Search
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    ref={this.search_ref}
                  />
                }
              />

              <Route
                path={process.env.PUBLIC_URL + "/watchlist/"} 
                render={(props) =>
                  <WatchList
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                  />
                }
              />

              <Route
                path={process.env.PUBLIC_URL + "/browse/"}
                render={(props) =>
                  <BrowseQueries
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    mode="random"
                  />
                }
              />

              <Route
                path={process.env.PUBLIC_URL + "/top/"}
                render={(props) =>
                  <BrowseQueries
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    mode="top"
                  />
                }
              />

              <Route
                path={process.env.PUBLIC_URL + "/movies-list/:list_id/"}
                render={(props) =>
                  <MoviesList
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/movies-list/"}
                render={(props) =>
                  <MoviesList
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                  />
                }
              />

              <Route
                path={lib.get_backwards_compatible_public_url() + "/:rec_type(movies|books|any)/:query"}
                render={(props) =>
                  <Search
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    ref={this.search_ref}
                  />
                }
              />
              <Route
                path={lib.get_backwards_compatible_public_url() + "/:rec_type(movies|books|any)/"}
                render={(props) =>
                  <Search
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                    ref={this.search_ref}
                  />
                }
              />
              <Route
                path={lib.get_backwards_compatible_public_url() + "/movies-list/:list_id/"}
                render={(props) =>
                  <MoviesList
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                  />
                }
              />
              <Route
                path={lib.get_backwards_compatible_public_url() + "/movies-list/"}
                render={(props) =>
                  <MoviesList
                    {...props}
                    navbar={this.state.navbar}
                    current_user={this.state.current_user}
                  />
                }
              />

              <Route path={process.env.PUBLIC_URL + "/books-list/:list_id"} component={BooksList}/>
              <Route path={process.env.PUBLIC_URL + "/any-list/:list_id"} component={AnyList}/>
              <Route path={process.env.PUBLIC_URL + "/books-list/"} component={BooksList}/>
              <Route path={process.env.PUBLIC_URL + "/any-list/"} component={AnyList}/>
              <Route path={process.env.PUBLIC_URL + "/about/"} component={About}/>

              <Route path={lib.get_backwards_compatible_public_url() + "/books-list/:list_id"} component={BooksList}/>
              <Route path={lib.get_backwards_compatible_public_url() + "/any-list/:list_id"} component={AnyList}/>
              <Route path={lib.get_backwards_compatible_public_url() + "/books-list/"} component={BooksList}/>
              <Route path={lib.get_backwards_compatible_public_url() + "/any-list/"} component={AnyList}/>
              <Route path={lib.get_backwards_compatible_public_url() + "/about/"} component={About}/>

              <Route component={Landing}/>
            </Switch>
          </Row>
        </Container>
      </BrowserRouter>
    );
  }
}
 
export default GPT3Recs;