import React from "react";
import { Row, Col, Container, Jumbotron, } from 'react-bootstrap';
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';

export default class About extends React.Component {
  render() {
    return (
      <Col sm={10} className="h-100 col-centered" id="main-content">
        <Container fluid className="min-h-100 flex-col">
          <Row className="spacer" />
          <Row>
            <Col>
              <Jumbotron fluid>
                <Container>
                  <h3>
                    How does it work?
                  </h3>
                  <h5>
                    {lib.get_domain()} is powered by a mix of different machine learning algorithms.
                  </h5>
                  <h5>
                    We are actively working to improve the service, please check back from time to time to see what has changed.
                  </h5>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
          <Row>
            <Col>
              <Jumbotron fluid>
                <Container>
                  <h3>
                    Product feedback
                  </h3>
                  <h5>
                    For product feedback, bugs report, and to share cool recommendations you found, join the 
                    <a href='https://discord.gg/Y9yqpbY'> {lib.get_domain()} Product Feedback Discord</a>
                  </h5>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
          <Row>
            <Col>
              <Jumbotron fluid>
                <Container>
                  <h3>
                    Suport
                  </h3>
                  <h5>
                    For support, contact us via email here: <a href={"mailto:" + lib.get_support_email()}> {lib.get_support_email()}</a>
                  </h5>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
          <Row>
            <Col>
              <Jumbotron fluid>
                <Container>
                  <h3>
                    Affiliate disclosure
                  </h3>
                  <h5>
                    {lib.get_domain()} supports itself by participating in the Amazon Affiliate program. We may earn a small commission when readers purchase products through our links.
                  </h5>
                  <h5>
                    This does not affect how the recommendations are ranked.
                  </h5>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
}