import React from "react";
import { Row, Col, Card, Badge, Button, } from 'react-bootstrap';
import axios from "axios";
// utilities for figuring out endpoints

export function get_endpoint_root(strip_port = false) {
  var hostname = window.location.hostname;
  if(window.location.port && !strip_port)
    hostname += ':' + window.location.port;
  return window.location.protocol + '//' + hostname + '/recs/';
}

export function get_backwards_compatible_public_url() {
  console.log(process.env.PUBLIC_URL);
  var tmp = process.env.PUBLIC_URL.split('/');
  console.log(tmp);
  var tmp2 = tmp.splice(0, tmp.length - 1).join('/') + '/gpt3recs';
  console.log(tmp2);
  return tmp2;
}

export function get_domain() {
  var tmp = get_endpoint_root();
  if(tmp.toLowerCase().indexOf('serendipityrecs') >= 0)
    return "Serendipity";
  if(tmp.toLowerCase().indexOf('localhost') >= 0 || tmp.toLowerCase().indexOf('127.0.0.1') >= 0)
    return "Serendipity-Local";
  return "OmniRex";
}

export function get_support_email()  {
  var tmp = get_endpoint_root();
  if(tmp.toLowerCase().indexOf('serendipityrecs') >= 0)
    return "serendipityrecshelp@gmail.com";
  if(tmp.toLowerCase().indexOf('localhost') >= 0 || tmp.toLowerCase().indexOf('127.0.0.1') >= 0)
    return "serendipityrecs.help@gmail.com";
  return "omnirexai@gmail.com";
}

export function get_proxy_api_endpoint(mode) {
  return get_endpoint_root(true) + 'api/' + mode + '/';
}

export function get_accounts_api_endpoint(mode) {
  return get_endpoint_root(true) + 'accounts/' + mode + '/';
}

export function get_url(mode, query = null) {
  if(mode == 'home')
    return get_endpoint_root(false);
  if(query === null)
      return encodeURI(get_endpoint_root(false) + mode + '/');
  return encodeURI(get_endpoint_root(false) + mode + '/' + query + '/');
}

export function stringify_error(err) {
  return (err.response && err.response.data) ? JSON.stringify(err.response.data) : "An error occurred";
}

export function open_url_in_new_tab(url) {
  if(url !== null)
    window.open(url, '_blank');
}

export function open_url_in_this_tab(url) {
  window.location = url;
}

export function log_click(imp_id, target, item_position = null, item_id = null, action = null) {
  axios
    .post(
      get_proxy_api_endpoint('log_click'),
      {
        imp_id: imp_id,
        item_position: item_position,
        item_id: item_id,
        target: target,
        action: action,
      },
    )
    .then((response) => {
      console.log("click logged");
    })
    .catch((err) => {
      // fail silently
      console.log(err);
      console.log(err.response);
    });
}

// stuff for movies

export function imdb_url(imdb_id) {
  return 'https://www.imdb.com/title/' + imdb_id + '/';
}

// extract imdb/meta/rotten scores from metadata
export function get_score(metadata, score_type) {
  if(score_type == 'Metacritic') {
    if('Metascore' in metadata && metadata['Metascore'] != 'N/A')
      return metadata['Metascore'];
  }
  if(score_type == 'IMDb') {
    if('imdbRating' in metadata && metadata['imdbRating'] != 'N/A')
      return metadata['imdbRating'];
  }
  if(!('Ratings' in metadata))
    return null;
  // parse ratings
  var ratings = {}
  metadata['Ratings'].forEach(item => {
    var key = null;
    if(item['Source'] == 'Internet Movie Database')
      key = 'IMDb';
    if(item['Source'] == 'Rotten Tomatoes')
      key = 'Rotten Tomatoes';
    if(item['Source'] == 'Metacritic')
      key = 'Metacritic';
    ratings[key] = item['Value'].split('/')[0];
  });
  if(!(score_type in ratings))
    return null;
  return ratings[score_type];
}

export function fetch_watchlist(navbar, source, response_handler, error_handler = (e) => {}) {
  console.log("fetching watchlist...");
  if(navbar.user_is_logged_in())
    axios
      .get(
        get_proxy_api_endpoint('fetch_watchlist'),
        {
          params: {
            source: source,
          },
        }
      )
      .then((response) => {
        if(response.data)
          response_handler(response.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        error_handler(err);
      });
}

// fetch seen/like/dislike data
export function fetch_item_relations(navbar, source, response_handler, error_handler = (e) => {}) {
  console.log("fetching item relations...");
  if(navbar.user_is_logged_in())
    axios
      .get(
        get_proxy_api_endpoint('fetch_item_relations'),
        {
          params: {
            source: source,
          },
        }
      )
      .then((response) => {
        if(response.data)
          response_handler(response.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        error_handler(err);
      });
}

// export function remove_from_watchlist(navbar, item, response_handler, onSuccess, onFail) {
//   if(!navbar.user_is_logged_in()) {
//     navbar.open_signup_modal('signup)');
//     onSuccess();
//   }
//   else
//     axios
//       .post(
//         get_proxy_api_endpoint('remove_from_watchlist'),
//         {
//           target: item,
//           rec_type: item.rec_type,
//         }
//       )
//       .then((response) => {
//         if(response.data)
//           response_handler(response);
//         onSuccess();
//       })
//       .catch((err) => {
//         console.log(err);
//         console.log(err.response);
//         onFail();
//       });
// }

export function update_watchlist(navbar, item, action, response_handler, onSuccess, onFail) {
  if(!navbar.user_is_logged_in()) {
    navbar.open_signup_modal('signup)');
    onSuccess();
  }
  else
    axios
      .post(
        get_proxy_api_endpoint('update_watchlist'),
        {
          target: item,
          action: action,
          rec_type: item.rec_type,
        }
      )
      .then((response) => {
        if(response.data)
          response_handler(response);
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        onFail();
      });
}

// deprecate?
export function add_to_watchlist(navbar, item, response_handler, onSuccess, onFail) {
  update_watchlist(navbar, item, 'add', response_handler, onSuccess, onFail);
}

// seen/unseen etc
export function update_item_relation(navbar, item, action, response_handler, onSuccess, onFail) {
  if(!navbar.user_is_logged_in()) {
    navbar.open_signup_modal('signup)');
    onSuccess();
  }
  else
    axios
      .post(
        get_proxy_api_endpoint('update_item_relation'),
        {
          target: item,
          action: action,
          rec_type: item.rec_type,
        }
      )
      .then((response) => {
        if(response.data)
          response_handler(response);
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        onFail();
      });
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalize_every_word(str) {
  var list = str.split(' ');
  return list.map(capitalize).join(' ');
}

// return the upper bound (non-inclusive) (ie exact value triggers next tier)
function get_breakpoints() {
  return [
    ['xs', 576],
    ['sm', 768],
    ['md', 992],
    ['lg', 1200],
    ['xl', 1000000],
  ]
}

function get_breakpoint_width(breakpoint) {
  var tmp = {
    'sm': 576,
    'md': 768,
    'lg': 992,
    'xl': 1200,
  };
  return tmp[breakpoint];
}

export function get_current_breakpoint(width) {
  var tmp = get_breakpoints();
  for(var i = 0; i < tmp.length; i++)
    if(width < tmp[i][1])
      return tmp[i][0];
  return 'xl';
}