import React from "react";
import { Row, Col, Container, Alert, ListGroup, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import update from 'react-addons-update';

// component to display a query with upvote interface...

class Query extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upvoted: false,
    };
  }

  render() {
    return (
      <ListGroup.Item>
        <a href={lib.get_url('movies', this.props.query_text)}>
          { this.props.query_text }
        </a>
      </ListGroup.Item>
    );
  }
}


// top vs random
export default class BrowseQueries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'ok',
      error_message: null,
      queries: [],
      upvote_status: [],
    };

  }

  componentDidMount() {
    this.fetch_queries();
  }

  renderStatus() {
    if(this.state.status == 'awaiting')
      return (
        <Row>
          <Col>
            <Row>
              <Col className={'centered'}>
                <i className="fa fa-spinner fa-pulse fa-spin fa-3x" />
              </Col>
            </Row>
            <Row>
              <Col className={'centered'}>
                Our minions are working...
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if(this.state.status == 'error')
      return (
        <Row>
          <Col className='centered'>
            <Alert variant="danger">
              Something went wrong: {this.state.error_message}
            </Alert>
          </Col>
        </Row>
      );
    return null;
  }

  render() {
    return (
      <Col sm={10} className="h-100 col-centered" id="main-content">
        {
          this.renderStatus()
        }
        <ListGroup>
        {
          this.state.queries.map(item =>
            <Query
              query_text = {item}
              key = {item}
            />
          )
        }
        </ListGroup>
      </Col>
    );
  }

  async fetch_queries(mode = 'random') {
    var endpoint = 'fetch_random_queries';
    if(mode == 'top')
      endpoint = 'fetch_top_queries';
    this.setState({
      status: 'awaiting',
    });
    await axios
      .post(
        lib.get_proxy_api_endpoint(endpoint),
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          status: 'ok',
          error_message: null,
          queries: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
          status: 'error',
          error_message: lib.stringify_error(err),
        });
      });
  }

}