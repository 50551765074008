import React from "react";
import { Row, Col, Container, Form, Card, Button, Alert, Badge, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import Any from './any';
import MovieCard from "./movieCard";

export default class Movies extends React.Component {
  constructor(props) {
    super(props);

    this.any_ref = React.createRef();
  }

  passed_query() {
    if(this.props.query)
      return this.props.query;
    if(this.props.match && this.props.match.params && this.props.match.params.query)
      return this.props.match.params.query;
    return '';
  }

  sort_by_score(recs, score_type) {
    // do a deep copy
    var sorted_recs = JSON.parse(JSON.stringify(recs));
    if(score_type == 'Relevance')
      return sorted_recs;
    sorted_recs
      .sort((a, b) => {
        var a_val = parseFloat(lib.get_score(a, score_type));
        var b_val = parseFloat(lib.get_score(b, score_type));
        if(a_val < b_val)
          return -1;
        if(a_val > b_val)
          return 1;
        if(a_val == b_val)
          return 0;
        console.log("Error! values are incomparable: ");
        console.log(a);
        console.log(b);
      })
      .reverse();
    return sorted_recs;
  }

  render() {
    return (
      <Any
        rec_type = 'movies'
        // fetch_metadata = {this.get_movie_metadata}
        Item = {MovieCard}
        query = {this.passed_query()}
        sort_options = {[
          ['Relevance', 'primary'],
          ['Rotten Tomatoes', 'danger'],
          ['Metacritic', 'success'],
          ['IMDb', 'warning'],
        ]}
        sort_func = {this.sort_by_score}
        default_sort_mode = {'Relevance'}
        navbar = {this.props.navbar}
        current_user = {this.props.current_user}
        metadata2uid = {(m) => m['imdbID']}
        metadata2title = {(m) => m['Title']}
        ref = {this.any_ref}
      />
    );
  }

  fetch_recs() {
    if(this.any_ref.current)
      this.any_ref.current.fetch_recs();
  }
}