import React from "react";
import { Row, Col, Container, Form, Alert, Badge, Button, Modal, ListGroup, Overlay, Tooltip } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import MediaNetAdUnit from "./mediaNetAdUnit";
import MetaTags from 'react-meta-tags';
import ReCAPTCHA from "react-google-recaptcha";

var debug_mode = (window.location.hostname == 'localhost');

export default class Any extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.passed_query(),
      recs: [],
      similar_queries: [],
      query_id: null,
      imp_id: null,
      variant: null,
      // json_recs: [],
      sort_mode: (this.props.default_sort_mode ? this.props.default_sort_mode : null),
      sorted_recs: [],
      watchlist: [],
      seen_items: [],
      liked_items: [],
      disliked_items: [],

      // statuses: idle, awaiting_recs, awaiting_metadata, error
      status: 'ok',
      error_message: null,

      // meta tags
      meta_title: "Recommendations For Anything You Want",
      meta_description: "AI Generated Recommendations For Anything You Want",
      meta_og_image: null,

      // id of the item that we want to show the item details modal for
      show_modal_id: null,
      show_captcha_modal: false,
      captcha_required: false,

      // show modal about gpt3 premium
      show_premium_required_modal: false,
      random_queries: [],

      show_tips: false,
    };

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    // if not an "any" rec, expecting to be told how to fetch metadata
    // if(this.rec_type() != 'any')
    //   this.fetch_metadata = this.props.fetch_metadata.bind(this);
    this.recaptcha_ref = React.createRef();
    this.tips_ref = React.createRef();
  }

  sort(items, mode) {
    if(this.props.sort_func)
      return this.props.sort_func(items, mode);
    // default sort is do nothing
    return items;
  }

  passed_query() {
    if(this.props.query)
      return this.props.query;
    if(this.props.match && this.props.match.params && this.props.match.params.query)
      return this.props.match.params.query;
    return '';
  }

  rec_type() {
    return this.props.rec_type || 'any';
  }

  metadata2title(item) {
    if(this.props.metadata2title)
      return this.props.metadata2title(item);
    return item;
  }

  initial_load_recs() {
    this.fetch_recs2(() => {
        var new_meta_description = "Top " + this.passed_query() + ":\n";
        this.state.recs.map((item, index) => {
          new_meta_description += ((index + 1) + ". " + this.metadata2title(item) + "\n");
        });
        this.setState({
          meta_description: new_meta_description,
        });
      },
      () => {}
    );
  }

  componentDidMount() {
    document.addEventListener("keypress", this.keyStrokeListener.bind(this), false);
    if(this.passed_query() != '') {
      this.initial_load_recs();
      this.setState({
        meta_title: lib.capitalize_every_word(this.passed_query()),
      })

    }
    else {
      this.setState({
        meta_title: lib.capitalize_every_word(this.rec_type() + " recommendations by " + lib.get_domain()),
      })
    }
  }

  // initially, navbar will be null, at some point, the ref will be set and flow in here...
  componentDidUpdate(prevProps) {
    if(
      (this.props.current_user && !prevProps.current_user) ||
      (this.props.current_user && prevProps.current_user && this.props.current_user.id != prevProps.current_user.id)
    ) {
      lib.fetch_watchlist(
        this.props.navbar,
        'recs',
        (rd) => {
          if('watchlist' in rd && 'rec_items' in rd.watchlist)
            this.setState({
              watchlist: rd.watchlist.rec_items,
            });
        }
      );
      lib.fetch_item_relations(
        this.props.navbar,
        'recs',
        (rd) => {
          console.log(rd);
          console.log(rd.seen_items);
          if('seen_items' in rd)
            this.setState({
              seen_items: rd.seen_items,
            });
          if('liked_items' in rd)
            this.setState({
              liked_items: rd.liked_items,
            });
          if('disliked_items' in rd)
            this.setState({
              disliked_items: rd.disliked_items,
            });
        }
      );
    }
  }

  do_search() {
    window.location = lib.get_url(this.rec_type(), this.state.query);
  }

  keyStrokeListener(e) {
    if(e.charCode == 13) {
      // don't do search if the login/register modal is up
      if(!this.props.navbar.modal_is_open())
        this.do_search();
    }
  }

  renderStatus() {
    if(this.state.status == 'awaiting_recs' || this.state.status == 'awaiting_metadata')
      return (
        <Row>
          <Col>
            <Row>
              <Col className={'centered'}>
                <i className="fa fa-spinner fa-pulse fa-spin fa-3x" />
              </Col>
            </Row>
            <Row>
              <Col className={'centered'}>
                {
                  (this.state.status == 'awaiting_recs') ?
                  "Our minions are working..." : "Our minions are still working..."
                }
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if(this.state.status == 'error')
      return (
        <Row>
          <Col className='centered'>
            <Alert variant="danger">
              Something went wrong: {this.state.error_message}
            </Alert>
          </Col>
        </Row>
      );
    return null;
  }

  // sorting based on scores
  renderSortBar() {
    if(this.props.sort_options) {
      var sort_options = this.props.sort_options;
      return (
        <Row>
          <Col xs={12} md={2} className="centered">
            Sort By:
          </Col>
          <Col>
          {
            sort_options.map(item =>
              <Badge
                className="sort-button"
                variant={
                  (this.state.sort_mode == item[0]) ? item[1] : 'secondary'
                }
                onClick = { () => {
                  this.setState({
                      sort_mode: item[0],
                      sorted_recs: this.sort(this.state.recs, item[0]),
                    },
                    () => {
                    }
                  );
                }}
                key={item[0]}
              >
                {item[0]}
              </Badge>
            )
          }
          </Col>
        </Row>
      );
    }
  }

  renderSimilarQueries() {
    if(this.state.similar_queries.length == 0)
      return null;
    if(!this.props.navbar.user_is_logged_in())
      return(
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item
                action
                onClick={()=>{
                  this.props.navbar.open_signup_modal('login');
                  // should we log this click?
                }}
              >
                <a href='#login'>
                  Log in to see similar queries
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      );
    return (
      <Container>
        <Row>
          <Col>
            <h5>Similar Queries</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {
                this.state.similar_queries.map((query, index) =>
                  <ListGroup.Item
                    key={query}
                  >
                    <a
                      href={lib.get_url(this.rec_type(), query)}
                      onClick={() => {
                        // get query id in there somehow?
                        lib.log_click(this.state.imp_id, 'q', index);
                      }}
                    >
                      {query}
                    </a>
                  </ListGroup.Item>
                )
              }
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }

  item_in_watchlist(item) {
    return this.state.watchlist
      .map(x => this.props.metadata2uid(x))
      .indexOf(this.props.metadata2uid(item)) > -1;
  }

  item_seen(item) {
    return this.state.seen_items
      .map(x => this.props.metadata2uid(x))
      .indexOf(this.props.metadata2uid(item)) > -1;
  }

  item_liked(item) {
    return this.state.liked_items
      .map(x => this.props.metadata2uid(x))
      .indexOf(this.props.metadata2uid(item)) > -1;
  }

  item_disliked(item) {
    return this.state.disliked_items
      .map(x => this.props.metadata2uid(x))
      .indexOf(this.props.metadata2uid(item)) > -1;
  }

  renderSearchInterface() {
    return (
      <Container>
        <Row className="spacer" />
        <Row>
          <Col xs={12} md={2} className="centered">
            Recommend me 
          </Col>
          <Col xs={12} md={9} >
            <Form.Control
              type="text"
              defaultValue={
                (this.passed_query() == '') ? (
                  this.rec_type() == 'any' ?
                  "movies that... " : this.rec_type() + " that... "
                ) : this.passed_query()
              }
              onChange={e => this.setState({query: e.target.value})}
            />
          </Col>
          <Col xs={12} md={1} >
            <SmartButton
              action={
                (s, f) => { this.do_search(); }
              }
            >
              Go
            </SmartButton>
          </Col>
        </Row>
        <Row className="spacer" />
        {
          this.renderStatus()
        }
        {
          this.state.captcha_required ?
          this.renderCaptchaUI() : null
        }
        {
          this.renderSimilarQueries()
        }
        <Row className="spacer" />
        {
          // for now only exists for movies
          this.renderSortBar()
        }
        {
          (this.rec_type() == 'any') ? 
          this.state.sorted_recs.map(item =>
            <Row key={item}>
              <Col>
                {item}
              </Col>
            </Row>
          ) :
          <Row className='justify-content-space-around'>
            {
              this.state.sorted_recs.map((item, index) =>
                <this.props.Item
                  metadata={item}
                  imp_id={this.state.imp_id}
                  index={index}
                  remove_action={(s, f) => {this.remove_rec(item, index, s, f);}}
                  watchlist_update_action={(action, s, f) => {
                    lib.update_watchlist(
                      this.props.navbar,
                      item,
                      action,
                      (response) => {
                        this.setState({
                          watchlist: response.data.watchlist.rec_items,
                        })
                      },
                      s,
                      f
                    );
                  }}
                  item_update_action={(action, s, f) => {
                    lib.update_item_relation(
                      this.props.navbar,
                      item,
                      action,
                      (response) => {
                        this.setState({
                          seen_items: response.data.seen_items,
                          liked_items: response.data.liked_items,
                          disliked_items: response.data.disliked_items,
                        })
                      },
                      s,
                      f
                    );
                  }}
                  item_in_watchlist={this.item_in_watchlist(item)}
                  item_seen={this.item_seen(item)}
                  item_liked={this.item_liked(item)}
                  item_disliked={this.item_disliked(item)}
                  show_modal={this.state.show_modal_id == this.props.metadata2uid(item)}
                  close_modal={ () => {
                    // don't understand why I need this...
                    this.setState({
                        show_modal_id: null,
                      },
                      () => {
                        this.setState({
                            show_modal_id: null,
                          },
                        );
                      }
                    );
                  }}
                  open_modal={ () => {
                    this.setState({
                      show_modal_id: this.props.metadata2uid(item),
                    });
                  }}
                  key={this.props.metadata2uid(item)}
                />
              )
            }
          </Row>
        }
      </Container>
    );
  }

  renderTips() {
    if(this.state.show_tips)
      return (
        <Row>
          <Col xs={12} md={3} className="centered capitalize">
            <h3>
              {this.rec_type()}
            </h3>
          </Col>
          <Col xs={12} md={9}>
            <h5>
              Tips for better results:
            </h5>
            <ul>
              <li>
                Use the plural ("movies that X" or "movies where X" instead of "a movie that X")
              </li>
              <li>
                Include the category noun of what you're looking for ("movies that X" or "romantic comedies where X" instead of "X")
              </li>
              <li>
                Check for spelling and spaces
              </li>
            </ul>
          </Col>
        </Row>
      );
    else
      return (
        <Row>
          <Col xs={12} md={{span:4, offset: 4}} className="centered capitalize">
            <h3>
              {this.rec_type()}
            </h3>
          </Col>
          <Col xs={12} md={2} className="centered">
            <h5
              ref={this.tips_ref}
              onClick={() => this.setState({
                show_tips: !this.state.show_tips,
              })}
              className="button-like"
            >
              <Badge variant="info">
                Show Search Tips
              </Badge>
            </h5>
          </Col>
        </Row>
      );
  }

  render() {
    return (
      <Col sm={10} className="h-100 col-centered">
        <MetaTags>
          <title>{this.state.meta_title}</title>
          <meta name="description" content={this.state.meta_description} />
          <meta property="og:title" content={this.state.meta_title} />
          <meta property="og:description" content={this.state.meta_description} />
          <meta property="og:image" content={this.state.meta_og_image} />
        </MetaTags>
        <Row>
          <Col sm={10} className="h-100 col-centered" id="main-content">
            <Container fluid className="min-h-100 flex-col">
              <Row className="spacer" />
              { this.renderTips() }
              <Row className="spacer" />
              {
                this.renderSearchInterface()
              }
              {
                this.renderCaptchaModal()
              }
              {
                this.renderPremiumRequiredModal()
              }
            </Container>
          </Col>
          {
            false ?
            <Col sm={2}>
              <MediaNetAdUnit />
            </Col> : null
          }
        </Row>
      </Col>
    );
  }

  renderCaptchaUI() {
    var sitekey = "6LcjsL0ZAAAAAGUMB2xad0XbdMQ6OpexU99vJMOg";
    if(debug_mode)
      sitekey = "6Lc_sr0ZAAAAANta5ikLpE_8Y084M-XKULum7_X3";
    return (
      <Container>
        <Row>
          <Col className="centered">
            <h3>
              Complete the captcha or <strong>Sign Up</strong> to continue using {lib.get_domain()}
            </h3>
          </Col>
        </Row>
        <Row className="spacer"/>
        <Row>
          <Col className="centered" xs={12} lg={6}>
            <form>
              <ReCAPTCHA
                sitekey={sitekey}
                ref={this.recaptcha_ref}
              />
              <br/>
              <SmartButton
                variant='outline-primary'
                action={(s, f) => {this.submit_captcha(s, f);}}
              >
                Submit
              </SmartButton>
            </form>
          </Col>
          <Col className="centered border-left" xs={12} lg={6}>
            <Button
              size='lg'
              onClick={() => {
                this.setState({
                  show_captcha_modal: false,
                });
                this.props.navbar.open_signup_modal('signup');
              }}
            >
              Sign Up
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  renderCaptchaModal() {
    if(this.state.show_captcha_modal)
      return (
        <Modal
          show={this.state.show_captcha_modal}
          onHide={() => {
            this.setState({
              show_captcha_modal: false,
            });
          }}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.renderCaptchaUI() }
          </Modal.Body>
        </Modal>
      );
    else
      return null;
  }

  // if new query is submitted and user does not have premium status
  renderPremiumRequiredModal() {
    return (
      <Modal
        show={this.state.show_premium_required_modal}
        onHide={() => {
          this.setState({
            show_premium_required_modal: false,
          });
        }}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Premium Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A premium account is required to perform this search.
          </p>
          <p>
            To register for premium, please <a href={"mailto:" + lib.get_support_email()}>contact support</a>.
          </p>
          <p>
            Or, you can check out some free queries results:
          </p>
          <Row>
            <Col>
              <ListGroup>
                {
                  this.state.random_queries.map((query, index) =>
                    <ListGroup.Item
                      key={query}
                    >
                      <a
                        href={lib.get_url(this.rec_type(), query)}
                        onClick={() => {
                          lib.log_click(this.state.imp_id, 'j', index);
                        }}
                      >
                        {query}
                      </a>
                    </ListGroup.Item>
                  )
                }
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  // v2 of fetch recs: ab test ranking algos 1/2
  async fetch_recs2(onSuccess, onFail) {
    console.log("fetching recs...");
    console.log(this.state.query);

    await this.setState({
      recs: [],
      sorted_recs: [],
      // json_recs: [],
      status: 'awaiting_recs',
      error_message: null,
    });

    await axios
      .post(
        lib.get_proxy_api_endpoint('recommend_v2'),
        {
          query: this.state.query,
          rec_type: this.rec_type(),
        },
      )
      .then((response) => {
        console.log(response.data);
        if(response.data.captcha === false) {
          this.setState({
              recs: response.data['recs'],
              sorted_recs: response.data['recs'],
              imp_id: response.data['imp_id'],
              query_id: response.data['query_id'],
              variant: response.data['variant'],
              captcha_required: false,
              status: 'ok',
              error_message: null,
            },
            () => {
              onSuccess();
              this.fetch_similar_queries();
            }
          );
          // gpt-3 issue, show modal
          if(response.data['status'] == 'no-gpt-3')
            this.setState({
              show_premium_required_modal: true,
              random_queries: response.data['random_queries'],
            });
        }
        else {
          // captcha required
          this.setState({
              imp_id: response.data['imp_id'],
              show_captcha_modal: true,
              captcha_required: true,
              status: 'ok',
              error_message: null,
            },
            () => {
              onSuccess();
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
            status: 'error',
            error_message: lib.stringify_error(err),
          },
        );
        onFail();
      });
  }

  // remove an item from the current ranked results. if not logged in, open up signup modal
  // if logged in and personalized list DNE, create. then, update personalized list <~ one endpoint for this
  async remove_rec(item, index, onSuccess, onFail) {
    if(!this.props.navbar.user_is_logged_in()) {
      this.props.navbar.open_signup_modal('signup');
      onSuccess();
    }
    else
      axios
        .post(
          lib.get_proxy_api_endpoint('modify_query_results'),
          {
            qrr_id: this.state.qrr_id,
            action: 'remove',
            target: item,
            imp_id: this.state.imp_id,
          },
        )
        .then((response) => {
          // console.log(response.data);
          this.setState({
              recs: response.data['recs'],
              // figure out how to keep the sort the same...
              sorted_recs: this.sort(response.data['recs'], this.state.sort_mode),
            },
            () => {
            }
          );
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          onFail();
        });
  }

  // submit captcha result
  async submit_captcha(onSuccess = () => {}, onFail = () => {}) {
    // just submit this to BE endpoint vvv
    console.log(this.recaptcha_ref.current.getValue());
    axios
      .post(
        lib.get_proxy_api_endpoint('solve_captcha'),
        {
          captcha_response_token: this.recaptcha_ref.current.getValue(),
          imp_id: this.state.imp_id,
        },
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
            show_captcha_modal: false,
            captcha_required: false,
          },
          () => {
            this.initial_load_recs();
          }
        );
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        onFail();
      });
  }

  // add to watchlist
  // async add_to_watchlist(item, onSuccess, onFail) {
  //   if(!this.props.navbar.user_is_logged_in()) {
  //     this.props.navbar.open_signup_modal('signup');
  //     onSuccess();
  //   }
  //   else
  //     axios
  //       .post(
  //         lib.get_proxy_api_endpoint('add_to_watchlist'),
  //         {
  //           target: item,
  //           rec_type: this.rec_type(),
  //         },
  //       )
  //       .then((response) => {
  //         console.log("added item to watch list");
  //         this.setState({
  //           watchlist: response.data.watchlist.rec_items,
  //         })
  //         onSuccess();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         console.log(err.response);
  //         onFail();
  //       });
  // }

  fetch_recs() {
    // don't fetch on sign in if we're the base search page (with no query)
    if(this.passed_query() != '')
      this.fetch_recs2(()=>{}, ()=>{});
  }

  async fetch_similar_queries() {
    await axios
      .post(
        lib.get_proxy_api_endpoint('fetch_similar_queries'),
        {
          query_id: this.state.query_id,
        },
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          similar_queries: response.data,
        })
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        // fail silently...
      });
  }
}