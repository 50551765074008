import React from "react";
import { Row, Col, Container, Form, Card, Button, Alert, Badge, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import MovieCard from "./movieCard";
import { MoviesSeedInterface } from "./movieslist";

export default class WatchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      watchlist: [],
      imp_id: null,
      show_add_movie_ui: false,
      show_details_modal_id: null,

      status: 'ok',
      error_message: null,
    };

    this.add_movie_modal = React.createRef();

    // this should be passed in depending on rec type
    this.metadata2uid = (m) => m['imdbID'];
  }

  componentDidUpdate(prevProps) {
    if(
      // initial load
      (this.props.current_user && !prevProps.current_user) ||
      // logging in / out
      (
        this.props.current_user && prevProps.current_user &&
        ('id' in this.props.current_user) && ('id' in prevProps.current_user) &&
        this.props.current_user != prevProps.current_user
      )
    ) {
      if(!(this.props.navbar.user_is_logged_in())) {
        this.props.navbar.open_signup_modal('signup');
        // reset list on log out
        this.setState({
          watchlist: [],
        })
        return;
      }
      this.setState({
        status: 'awaiting',
      });
      lib.fetch_watchlist(
        this.props.navbar,
        'watchlist',
        (rd) => {
          this.setState({
            watchlist: rd.watchlist.rec_items,
            imp_id: rd.imp_id,
            status: 'ok',
          });
        },
        (e) => {
          this.setState({
            status: 'error',
            error_message: lib.stringify_error(e),
          });
        },
      );
    }
  }

  componentDidMount() {
    document.addEventListener("keypress", this.keyStrokeListener.bind(this), false);
  }

  keyStrokeListener(e) {
    if(e.charCode == 13) {
      this.add_movie_modal.current.seedSearchButton.button.click();
    }
  }

  renderStatus() {
    if(this.state.status == 'awaiting')
      return (
        <Row>
          <Col>
            <Row>
              <Col className={'centered'}>
                <i className="fa fa-spinner fa-pulse fa-spin fa-3x" />
              </Col>
            </Row>
            <Row>
              <Col className={'centered'}>
                Our minions are working...
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if(this.state.status == 'error')
      return (
        <Row>
          <Col className='centered'>
            <Alert variant="danger">
              Something went wrong: {this.state.error_message}
            </Alert>
          </Col>
        </Row>
      );
    return null;
  }

  render() {
    return (
      <Col sm={10} className="h-100 col-centered" id="main-content">
        <Row>
          <Col xs={12} sm={8} className="centered capitalize">
            <h3>
              Your Watchlist
            </h3>
          </Col>
          <Col xs={12} sm={4}>
            <Button
              onClick={() => {
                this.setState(
                  {show_add_movie_ui: true},
                  () => {
                    this.add_movie_modal.current.focus();
                  }
                );
              }}
            >
              Add Item
            </Button>
          </Col>
        </Row>
        {
          this.renderStatus()
        }
        <Row className='justify-content-space-around'>
         {
          this.state.watchlist.map((item, index) =>
            <MovieCard
              metadata={item}
              imp_id={this.state.imp_id}
              index={index}
              watchlist_mode={true}
              show_add_button={false}

              show_modal={this.state.show_details_modal_id == this.metadata2uid(item)}
              close_modal={() => {
                this.setState({
                  show_details_modal_id: null,
                })
              }}
              open_modal={ () => {
                console.log("opening modal...?");
                this.setState({
                  show_details_modal_id: this.metadata2uid(item),
                });
              }}
              is_result={true}
              remove_action={(s, f) => {
                lib.update_watchlist(
                  this.props.navbar,
                  item,
                  'remove',
                  (response) => {
                    this.setState({
                      watchlist: response.data.watchlist.rec_items,
                    })
                  },
                  s, f
                );
              }}
            />
          )
         }
        </Row>
        {
          <MoviesSeedInterface
            show={this.state.show_add_movie_ui}
            close={() => {
              this.setState({
                show_add_movie_ui: false,
              })
            }}
            add_seed={
              (s) => {
                // console.log("adding item to watchlist...");
                // console.log(s);
                // add item to watchlist lol...
                lib.add_to_watchlist(
                  this.props.navbar,
                  s,
                  (response) => {
                    this.setState({
                      watchlist: response.data.watchlist.rec_items,
                    })
                  },
                  () => {},
                  () => {}
                );
              }
            }
            ref={this.add_movie_modal}
          />
        }
      </Col>
    );
  }

}