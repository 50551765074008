import React from "react";
import { Container, Row, Col, Button, Modal, Tabs, Tab, Form, Alert, } from 'react-bootstrap';
import * as lib from '../lib';
import axios from "axios";
import SmartButton from "./smartButton";
import update from 'react-addons-update';


export default class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      signup_error_message: null,
      login_error_message: null,
      signup_data: {},
      login_data: {},
      status: 'ok',
      registration_status: 'unregistered',
      // unregistered, awaiting_confirmation
    };

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  }

  componentDidUpdate(prevProps) {
    if(prevProps.mode !== this.props.mode) {
      this.setState({
        mode: this.props.mode,
      });
      console.log("updating mode to " + this.props.mode);
    }
  }

  map_mode(mode) {
    var mapping = {
      'signup': 'Sign Up',
      'login': 'Log In',
    }
    return mapping[mode];
  }

  update_signup_data(k, v) {
    this.setState({
      signup_data: update(
        this.state.signup_data,
        {[k]: {$set: v}}
      ),
    })
  }

  update_login_data(k, v) {
    this.setState({
      login_data: update(
        this.state.login_data,
        {[k]: {$set: v}}
      ),
    })
  }

  // features to build out...
                // <li>Save items to your watch list/read list</li>
                // <li>Save queries to quickly find them again</li>
                // <li>Browse popular queries</li>

  render_signup_form() {
    if(this.state.registration_status == 'unregistered')
      return (
        <Container>
          <Row className="m-t-1-rem">
            <Col>
              <h4>
                Sign up for a free account to access the following features
              </h4>
              <ul>
                <li>Personalize your results by removing or reordering recommended items</li>
                <li>Improved recommendations over time that learns from your feedback</li>
                <li>Save items to your watchlist</li>
                <li>See similar queries from other users</li>
              </ul>
            </Col>
          </Row>
          <Row className="m-t-1-rem">
            <Col xs={12} md={3} className="centered">
              Username
            </Col>
            <Col xs={12} md={9} >
              <Form.Control
                type="text"
                onChange={e => {this.update_signup_data('username', e.target.value);}}
              />
            </Col>
          </Row>
          <Row className="m-t-1-rem">
            <Col xs={12} md={3} className="centered">
              Email
            </Col>
            <Col xs={12} md={9} >
              <Form.Control
                type="text"
                onChange={e => {this.update_signup_data('email', e.target.value);}}
              />
            </Col>
          </Row>
          <Row className="m-t-1-rem">
            <Col xs={12} md={3} className="centered">
              Password
            </Col>
            <Col xs={12} md={9} >
              <Form.Control
                type="password"
                onChange={e => {this.update_signup_data('password', e.target.value);}}
              />
            </Col>
          </Row>
          <Row className="m-t-1-rem">
            <Col xs={12} md={3} className="centered">
              Confirm Password
            </Col>
            <Col xs={12} md={9} >
              <Form.Control
                type="password"
                onChange={e => {this.update_signup_data('password_verify', e.target.value);}}
              />
            </Col>
          </Row>
          {
            (this.state.signup_error_message === null) ? null :
            <Row className="m-t-1-rem">
              <Col>
                <Alert variant="danger">
                  {this.state.signup_error_message}
                </Alert>
              </Col>
            </Row>
          }
          <Row className="m-t-1-rem">
            <Col xs={12} className="display-flex justify-content-end">
              <SmartButton
                action={(s, f) => {this.sign_up(s, f);}}
              >
                Sign Up
              </SmartButton>
            </Col>
          </Row>
        </Container>
      );
    else
      return (
        <Container>
          <Row className="m-t-1-rem">
            <Col xs={12} className="centered">
              <div>
                Please check your email for the confirmation token. You will need to confirm your email before logging in.
              </div>
            </Col>
          </Row>
        </Container>
      );
  }

  render_login_form() {
    return (
      <Container>
        <Row className="m-t-1-rem">
          <Col xs={12} md={3} className="centered">
            Username / email
          </Col>
          <Col xs={12} md={9} >
            <Form.Control
              type="text"
              onChange={e => {this.update_login_data('username', e.target.value);}}
            />
          </Col>
        </Row>
        <Row className="m-t-1-rem">
          <Col xs={12} md={3} className="centered">
            Password
          </Col>
          <Col xs={12} md={9} >
            <Form.Control
              type="password"
              onChange={e => {this.update_login_data('password', e.target.value);}}
            />
          </Col>
        </Row>
        {
          (this.state.login_error_message === null) ? null :
          <Row className="m-t-1-rem">
            <Col>
              <Alert variant="danger">
                {this.state.login_error_message}
              </Alert>
            </Col>
          </Row>
        }
        <Row className="m-t-1-rem">
          <Col xs={12} className="display-flex justify-content-end">
            <SmartButton
              action={(s, f) => {this.log_in(s, f);}}
            >
              Log In
            </SmartButton>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    // keep old nav for now as smart lists aren't ready yet
    return (
      <Modal show={this.props.show} onHide={this.props.close} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{this.map_mode(this.state.mode)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={this.state.mode}
            id="signup-modal-tab"
            onSelect={(k) => {
              this.setState({
                mode: k,
              })
            }}
          >
            <Tab eventKey="signup" title="Sign Up">
              {
                this.render_signup_form()
              }
              
            </Tab>
            <Tab eventKey="login" title="Log In">
              {
                this.render_login_form()
              }
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }

  local_checks(data) {
    if(!('username' in data))
      return [false, "Username can't be empty"];
    if(!('password' in data) || !('password_verify' in data) || (data['password'] != data['password_verify']))
      return [false, "Passwords don't match"];
    return [true, null];
  }

  async sign_up(onSuccess = () => {}, onFail = () => {}) {
    // do local checks first
    var tmp = this.local_checks(this.state.signup_data);
    this.setState({
      signup_error_message: tmp[1],
    })
    if(!tmp[0]) {
      onFail();
      return;
    }
    await axios
      .post(
        lib.get_accounts_api_endpoint('sign_up'),
        this.state.signup_data,
      )
      .then((response) => {
        console.log(response.data);
        this.setState({
          registration_status: "awaiting_confirmation",
        })
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
            status: 'error',
            signup_error_message: lib.stringify_error(err),
          },
        );
        onFail();
      });
  }

  async log_in(onSuccess = () => {}, onFail = () => {}) {
    await axios
      .post(
        lib.get_accounts_api_endpoint('log_in'),
        this.state.login_data,
      )
      .then((response) => {
        console.log(response.data);
        this.props.close();
        this.props.fetch_current_user();
        this.props.sign_in_action();
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        this.setState({
            status: 'error',
            login_error_message: lib.stringify_error(err),
          },
        );
        onFail();
      });
  }
}