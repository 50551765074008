// UI element that disables itself on click and then reenables on success or failure
// expects a action function that takes two callbacks: success and failure, and also
// success/failure callbacks that do the actual logic handling at the parent level,
// which we will inject with additional logic to handle button state
import React from "react";
import { Button, } from 'react-bootstrap';

export default class SmartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      error: false,
    };

    this.button = null;
    this.setButtonRef = element => { this.button = element; };
  }

  render() {
    return(
      <Button
        variant={this.props.variant || "primary"}
        size={this.props.size || null}
        onClick={
          () => {
            this.setState({
              waiting: true,
            });
            this.props.action(
              () => {
                this.setState({
                  waiting: false,
                  error: false,
                });
              },
              () => {
                this.setState({
                  waiting: false,
                  error: true,
                });
              }
            );
          }
        }
        disabled={this.props.disabled || this.state.waiting}
        ref={this.setButtonRef}
      >
        <div className="display-flex">
          {
            this.props.children
          }
          <div className={'centered' + (this.state.waiting ? '' : ' hide')}>
            <i className="fa fa-spinner fa-spin fa-lg" />
          </div>
          <div className={'centered' + (this.state.error ? '' : ' hide')}>
            <i className="fa fa-warning fa-lg" />
          </div>
        </div>
      </Button>
    );
  }
}