import React from "react";
import * as lib from '../lib';
import Any from './any';
import Movies from './movies';
import Books from './books';

// top level router for different kinds of recs/queries, so we don't have a billion lines in the router
export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.specific_search = React.createRef();
  }

  passed_query() {
    if(this.props.query)
      return this.props.query;
    if(this.props.match && this.props.match.params && this.props.match.params.query)
      return this.props.match.params.query;
    return '';
  }

  rec_type() {
    if(this.props.rec_type)
      return this.props.rec_type;
    if(this.props.match && this.props.match.params && this.props.match.params.rec_type)
      return this.props.match.params.rec_type;
    return '';
  }

  render() {
    if(this.rec_type() == 'movies')
      return (
        <Movies
          query = {this.passed_query()}
          navbar = {this.props.navbar}
          current_user = {this.props.current_user}
          ref = {this.specific_search}
        />
      );
    if(this.rec_type() == 'books')
      return (
        <Books
          query = {this.passed_query()}
          navbar = {this.props.navbar}
          current_user = {this.props.current_user}
          ref = {this.specific_search}
        />
      );
    return (
      <Any
        query = {this.passed_query()}
        navbar = {this.props.navbar}
        current_user = {this.props.current_user}
        metadata2uid = {(m) => m}
        ref = {this.specific_search}
      />
    );
  }

  fetch_recs() {
    if(this.specific_search.current)
      this.specific_search.current.fetch_recs();
  }
}