import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import AnyList from './anylist';

export default class BooksList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AnyList
        rec_type = 'movies'
      />
    );
  }
}