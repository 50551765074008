import React from 'react';
// import { Row, Col, Container } from 'react-bootstrap';

export default class MediaNetAdUnit extends React.Component {
  constructor(props) {
    super(props);
    this.instance = null;
  }

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = " \
try { \
  window._mNHandle.queue.push(function (){ \
    window._mNDetails.loadTag(\"788634347\", \"160x600\", \"788634347\"); \
  }); \
} \
catch (error) {} \
";
    // s.innerHTML = "console.log('zuh')";

    this.instance.appendChild(s);
  }

  render() {
    return (
      <div id="788634347" ref={el => (this.instance = el)} />
    );
  }
}