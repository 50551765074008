import React from "react";
import { Row, Col, Container, Jumbotron, } from 'react-bootstrap';
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';

export default class Contact extends React.Component {
  render() {
    return (
      <Col sm={10} className="h-100 col-centered" id="main-content">
        <Container fluid className="min-h-100 flex-col">
          <Row className="spacer" />
          <Row>
            <Col>
              <Jumbotron fluid>
                <Container>
                  <h5>
                    For product feedback, bugs report, and to share cool recommendations you found, join the 
                    <a href='https://discord.gg/Y9yqpbY'> Serendipity Product Feedback Discord</a>
                  </h5>
                  <h5></h5>
                  <h5>
                    For support, contact us via email here: <a href='mailto:serendipityrecshelp@gmail.com'> serendipityrecshelp@gmail.com</a>
                  </h5>
                </Container>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
}