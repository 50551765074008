import React from "react";
import { Row, Col, Card, Badge, Button, Modal, Image, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import {SizeMe} from 'react-sizeme';

var score_types = [
  ['Rotten Tomatoes', 'RT', 'danger'],
  ['Metacritic', 'MC', 'success'],
  ['IMDb', 'imdb', 'warning'],
];

const renderTooltip = (props) => (
  <Tooltip {...props}>
    Watchlist
  </Tooltip>
);

// component for rendering preview card for movie search page
export default class MovieCard extends React.Component {
  constructor(props) {
    super(props);
    // required props: metadata, imp_id, index
    // optional: onClick, is_result
    this.state = {
      show_overlay: false,
    };

    this.watchlist_hover_ref = React.createRef();
    this.seen_hover_ref = React.createRef();
    this.like_hover_ref = React.createRef();
    this.dislike_hover_ref = React.createRef();
  }

  // need to rename this... this is used by anylist to turn of remove for smartlists
  // should just callit show_delete or something like that
  is_result() {
    if('is_result' in this.props)
      return this.props.is_result;
    return true;
  }

  watchlist_mode() {
    if('watchlist_mode' in this.props)
      return this.props.watchlist_mode;
    return false;
  }

  show_add_button() {
    if('show_add_button' in this.props)
      return this.props.show_add_button;
    return true;
  }

  get_metadata(field) {
    if(this.props.metadata && (field in this.props.metadata))
      return this.props.metadata[field];
    return null;
  }

  title_text() {
    var title_text = this.get_metadata('Title');
    if(this.get_metadata('Year'))
      title_text += ' (' + this.get_metadata('Year') + ')';
    return title_text;
  }

  imdb_id() {
    return this.get_metadata('imdbID');
  }

  plot_truncated() {
    if(this.get_metadata('Plot'))
      return (this.get_metadata('Plot').slice(0, 100) + '...');
    return null;
  }

  onClick() {
    if('onClick' in this.props)
      this.props.onClick();
    else {
      // default behavior is log a click and then open modal
      lib.log_click(this.props.imp_id, 'r', this.props.index, this.imdb_id());
      // if(this.imdb_id())
      //   lib.open_url_in_new_tab(lib.imdb_url(this.imdb_id()));
      this.props.open_modal();
    }
  }

  get_trailer_dim(mode = 'width') {
    // don't video width so we can control the look
    // this.get_metadata('trailer_info')['size']
    if(mode == 'width')
      return 720;
    if(mode == 'height')
      return 405;
  }

  get_responsive_component_dim(component, breakpoint, aspect = 'width') {
    var dim = null;
    if(component == 'trailer') {
      switch(breakpoint) {
        case 'xs':
          dim = [400, 400*9/16];
          break;
        case 'sm':
        case 'md':
          dim = [496, 496*9/16];
          break;
        case 'lg':
          dim = [300*16/9, 300];
          break;
        case 'xl':
          dim = [438*16/9, 438];
          break;
      }
      if(aspect == 'width')
        return dim[0];
      return dim[1];
    }
    // if(component == 'poster') {
    //   switch(breakpoint) {
    //     case 'xs':
    //       dim = [null, 405];
    //       break;
    //     case 'sm':
    //     case 'md':
    //       dim = [null, 405];
    //       break;
    //     case 'lg':
    //       dim = [null, 405];
    //       break;
    //     case 'xl':
    //       dim = [null, 405];
    //       break;
    //   }
    //   if(aspect == 'width')
    //     return dim[0];
    //   return dim[1];
    // }
  }

  renderTrailer(breakpoint) {
    var trailer_info = this.get_metadata('trailer_info') || {};
    if('site' in trailer_info && trailer_info['site'] == 'youtube' && 'key' in trailer_info)
      return (
        <iframe
          width={this.get_responsive_component_dim('trailer', breakpoint, 'width')}
          height={this.get_responsive_component_dim('trailer', breakpoint, 'height')}
          src={"https://www.youtube.com/embed/" + trailer_info['key']}
          frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
          onClick = {() => {
            // this doesn't work...
            console.log("clicked...?");
            lib.log_click(this.props.imp_id, 't', this.props.index, this.imdb_id());
          }}
        />
      );
    return(
      <div
        width={this.get_responsive_component_dim('trailer', breakpoint, 'width')}
        height={this.get_responsive_component_dim('trailer', breakpoint, 'height')}
      >
        Trailer not available
      </div>
    )
  }

  renderPoster(breakpoint) {
    return (
      <Image
        src = { this.get_metadata('Poster') }
        height={this.get_responsive_component_dim('trailer', breakpoint, 'height')}
        onClick = {() => {
          lib.open_url_in_new_tab(this.get_amazon_link());
          lib.log_click(this.props.imp_id, 'p', this.props.index, this.imdb_id());
        }}
        className = "hover-image"
      />
    );
  }

  get_amazon_link() {
    var amazon_info = this.get_metadata('amazon_info') || {};
    if('link' in amazon_info)
      return amazon_info['link'];
    return null;
  }

  render_amazon_button() {
    var amazon_link = this.get_amazon_link();
    if(amazon_link !== null)
      return (
        <Button
          size="lg"
          onClick={() => {
            lib.open_url_in_new_tab(amazon_link);
            lib.log_click(this.props.imp_id, 'w', this.props.index, this.imdb_id());
          }}
        >
          Watch Now
        </Button>
      );
  }

  // modal with extra info including trailer, amazon link
  renderModal() {
    if(this.props.show_modal)
      console.log(this.props.metadata);
    return (
      <SizeMe monitorHeight>{({size}) =>
      <Modal show={this.props.show_modal} onHide={this.props.close_modal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{this.title_text()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // for debugging
            false ?
            <Row>
              <Col>
                {size.width}
              </Col>
              <Col>
                {size.height}
              </Col>
              <Col>
                {lib.get_current_breakpoint(size.width)}
              </Col>
            </Row> : null
          }
          <Row className="justify-content-space-around">
            <div
              className="centered"
            >
              {this.renderPoster(lib.get_current_breakpoint(size.width))}
            </div>
            <div
              className="centered"
            >
              {this.renderTrailer(lib.get_current_breakpoint(size.width))}
            </div>
          </Row>
          <Row className="spacer" />
          <Row>
            <Col md={4}>
              <Row>
                <Col>
                  { this.render_amazon_button() }
                </Col>
              </Row>
              <Row className="spacer" />
              <Row>
                <Col>
                  { this.render_add_to_watchlist() }
                </Col>
              </Row>
              <Row className="spacer" />
              <Row>
                <Col>
                  { this.render_remove_button() }
                </Col>
              </Row>
            </Col>
            <Col md={8}>
              <Row>
                <Col xs={12} sm={6} md={8} lg={5} xl={4}>
                  {
                    this.render_score_bar(
                      lib.get_current_breakpoint(size.width) == 'xs' ||
                      lib.get_current_breakpoint(size.width) == 'sm'
                    )
                  }
                </Col>
              </Row>
              <Row className="spacer" />
              <Row>
                <Col>
                { this.get_metadata('Plot') }
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      }</SizeMe>
    );
  }

  render_add_to_watchlist() {
    return (
      this.show_add_button() ?
      <SmartButton
        variant="outline-primary"
        size="sm"
        action={ (s, f) => {
          lib.log_click(this.props.imp_id, 'a', this.props.index, this.imdb_id());
          this.props.watchlist_update_action('add', s, f);
        }}
        disabled = {this.props.item_in_watchlist}
      >
        {
          this.props.item_in_watchlist ? "Added to Watchlist" : "Add to My Watchlist"
        }
      </SmartButton> : null
    );
  }

  render_remove_button() {
    return (
      this.is_result() ?
      <SmartButton
        variant="outline-danger"
        size="sm"
        action={ (s, f) => {
          lib.log_click(this.props.imp_id, 'x', this.props.index, this.imdb_id());
          this.props.remove_action(s, f);
        }}
      >
        {
          this.watchlist_mode() ?
          "Remove From My Watchlist" :
          "Remove This Recommendation"
        }
      </SmartButton> : null
    );
  }

  render_score_bar(set_margin_top = false) {
    return (
      <Row className={'justify-content-space-between m-l-r-0' + (set_margin_top ? ' m-t-1-rem' : '')}>
      {
        score_types.map(item =>
          (lib.get_score(this.props.metadata, item[0]) !== null) ?
          <Badge variant={item[2]} key={this.imdb_id() + item[0]}>
            {item[1] + ": " + lib.get_score(this.props.metadata, item[0]) + '  '}
          </Badge> : null
        )
      }
      </Row>
    );
  }

  render_overlay() {
    // console.log(this.watchlist_mode());
    if(this.state.show_overlay)
      return (
        <div className="overlay z-2">
          <Row className='justify-content-space-around m-l-r-0 m-t-1-rem'>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Watchlist</Tooltip>}
            >
              <i
                className={"fa fa-bookmark" +
                  (this.props.item_in_watchlist ? '' : '-o') +
                  " fa-2x movie-card-button"
                }
                ref={this.watchlist_hover_ref}
                onClick={()=>{
                  lib.log_click(
                    this.props.imp_id,
                    'W', // watchlist-overlay
                    this.props.index,
                    this.imdb_id(),
                    this.props.item_in_watchlist ? 'remove' : 'add',
                  );
                  this.props.watchlist_update_action(
                    this.props.item_in_watchlist ? 'remove' : 'add',
                    ()=>{},
                    ()=>{}
                  );
                }}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Seen</Tooltip>}
            >
              <i
                className={"fa fa-eye" +
                  (this.props.item_seen ? '' : '-slash') +
                  " fa-2x movie-card-button"
                }
                ref={this.seen_hover_ref}
                onClick={()=>{
                  lib.log_click(
                    this.props.imp_id,
                    'S',
                    this.props.index,
                    this.imdb_id(),
                    this.props.item_seen ? 'unseen' : 'seen',
                  );
                  this.props.item_update_action(
                    this.props.item_seen ? 'unseen' : 'seen',
                    ()=>{},
                    ()=>{}
                  );
                }}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Like</Tooltip>}
            >
              <i
                className={"fa fa-thumbs" +
                  (this.props.item_liked ? '-up' : '-o-up') +
                  " fa-2x movie-card-button"
                }
                ref={this.like_hover_ref}
                onClick={()=>{
                  lib.log_click(
                    this.props.imp_id,
                    'L',
                    this.props.index,
                    this.imdb_id(),
                    this.props.item_liked ? 'unlike' : 'like',
                  );
                  this.props.item_update_action(
                    this.props.item_liked ? 'unlike' : 'like',
                    ()=>{},
                    ()=>{}
                  );
                }}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Dislike</Tooltip>}
            >
              <i
                className={"fa fa-thumbs" +
                  (this.props.item_disliked ? '-down' : '-o-down') +
                  " fa-2x movie-card-button"
                }
                ref={this.dislike_hover_ref}
                onClick={()=>{
                  lib.log_click(
                    this.props.imp_id,
                    'D',
                    this.props.index,
                    this.imdb_id(),
                    this.props.item_disliked ? 'unlike' : 'dislike',
                  );
                  this.props.item_update_action(
                    this.props.item_disliked ? 'unlike' : 'dislike',
                    ()=>{},
                    ()=>{}
                  );
                }}
              />
            </OverlayTrigger>

          </Row>
        </div>
      );
  }

  render_overlay_gradient() {
    if(this.state.show_overlay)
      return (
        <div className="overlay z-1 overlay-gradient">
        </div>
      );
  }

  render() {
    return (
      <div
        className='overlay-container'
        onMouseOver={() => { this.setState({show_overlay: true}); }}
        onMouseLeave={() => { this.setState({show_overlay: false}); }}
      >
        <Card
          className='movie-card'
          key={this.title_text()}
        >
          {this.renderModal()}
          <Card.Img
            variant = "top"
            src = { this.get_metadata('Poster') }
            onClick={() => { this.onClick(); }}
          />
          <Card.Body
            onClick={() => { this.onClick(); }}
          >
            <Card.Title>{this.title_text()}</Card.Title>
            {this.render_score_bar(true)}
            <Card.Text>
              {
                this.plot_truncated()
              }
            </Card.Text>
          </Card.Body>
        </Card>
        {this.render_overlay_gradient()}
        {this.render_overlay()}
      </div>
    );
  }
}