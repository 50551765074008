import React from "react";
import { Row, Col, Container, Carousel, Jumbotron, Button, } from 'react-bootstrap';
import * as lib from '../lib';

// todo: put landing links into a list and loop over them to render
class Landing extends React.Component {

  get_categories() {
    return [
      'movies',
      'books',
      'places',
      'shopping',
      'music',
      'games & anime',
    ];
  }

  get_landing_page_links(category) {
    var links_data = {
      'movies': [
        ['Kids-friendly movies from the 2000s', 'movies', 'kids-friendly movies from the 2000s'],
        ['Romantic comedies startting Matt Damon but not Ben Affleck', 'movies', 'romantic comedies starring matt damon but not ben affleck'],
        ["Movies that I'm going to have to explain the plot to my friends because they won't get it on the first viewing",
          'movies', "movies that I'm going to have explain the plot to my friends because they won't get it on the first viewing"],
      ],
      'books': [
        ['Pulitzer prize winning novels published after 2001', 'books', 'pulitzer prize winning novels published after 2001'],
        ['Books that won both the Hugo and Nebula', 'books', 'books that won both the hugo and nebula'],
        ['Historical romance books that are historically accurate', 'books', 'historical romance books that are historically accurate'],
      ],
      'places': [
        ['Cocktail bars in Austin, Texas', 'any', 'cocktail bars in austin, texas'],
        ['Italian restaurants in New York City', 'any', 'italian restaurants in new york city'],
        ["Date activities in Los Angeles that don't require a lot of money", 'any', "date activities in los angeles that don't require a lot of money"],
      ],
      'shopping': [
        ['Bluetooth speakers for digital nomads under $100', 'any', 'bluetooth speakers for digital nomads under $100'],
        ['Mid-price range digital pianos', 'any', 'mid-price range digital pianos'],
      ],
      'music': [
        ['Post-rock instrumental bands', 'any', 'post-rock instrumental bands'],
        ['Math-rock albums that feature minor keys and female vocals', 'any', 'math-rock albums that feature minor keys and female vocals'],
      ],
      'games & anime': [
        ['Story focused video games', 'any', 'story focused video games'],
        ['Board games for three players with a historical theme', 'any', 'board games for three players with a historical theme'],
        ['2010s Isekai animes', 'any', '2010s isekai animes'],
      ],
    };
    return links_data[category];
  }

  renderCategory(category) {
    return(
      <Col xs={12} sm={4}>
        <h5 className="capitalize">
          {category}
        </h5>
        <ul>
          {
            this.get_landing_page_links(category).map((item, index) =>
              <li>
                <a
                  href={'/recs/' + item[1] + '/' + item[2]}
                  onClick={(e) => {
                    e.preventDefault();
                    lib.log_click(null, 'l', null, category + '-' + index);
                    if(e.ctrlKey)
                      lib.open_url_in_new_tab(lib.get_url(item[1], item[2]));
                    else
                      lib.open_url_in_this_tab(lib.get_url(item[1], item[2]));
                  }}
                >
                  {item[0]}
                </a>
              </li>
            )
          }
        </ul>
      </Col>
    );
  }

  render() {
    return (
      <Col className="h-100 flex-col col-centered">
        <Row>
          <Col sm={10} className="col-centered main-content-landing">
            <Jumbotron fluid className='landing-header'>
              <Container>
                <h1>
                  {lib.get_domain()} is an AI powered recommendation engine for anything you want.
                </h1>
                <p></p>
                <p>
                  Just describe what you want in plain English.
                </p>
              </Container>
            </Jumbotron>
          </Col>
        </Row>

        <Row>
          <Col>
            <Carousel
              autoPlay={true}
              interval={5000}
            >
              <Carousel.Item
                onClick={() => {
                  lib.log_click(null, 'c', 0);
                  lib.open_url_in_this_tab(lib.get_url('movies', 'movies to watch when stuck at home because there is a global pandemic'));
                }}
              >
                <img
                  className="d-block w-100"
                  src="/recs/static/assets/gpt3recs-movies-3c.png"
                  alt="Movies to watch when stuck at home because there is a global pandemic"
                />
              </Carousel.Item>
              <Carousel.Item
                onClick={() => {
                  lib.log_click(null, 'c', 1);
                  lib.open_url_in_this_tab(lib.get_url('movies', "movies that I'm going to have explain the plot to my friends because they won't get it on the first viewing"));
                }}
              >
                <img
                  className="d-block w-100"
                  src="/recs/static/assets/gpt3recs-movies-2c.png"
                  alt="Movies that I'm going to have to explain the plot to my friends because they won't get it on the first viewing"
                />
              </Carousel.Item>
              <Carousel.Item
                onClick={() => {
                  lib.log_click(null, 'c', 2);
                  lib.open_url_in_this_tab(lib.get_url('movies', 'movies with empowered female protagonists'));
                }}
              >
                <img
                  className="d-block w-100"
                  src="/recs/static/assets/gpt3recs-movies-1c.png"
                  alt="Movies with empowered female protagonists"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

        <Row className="spacer" />

        <Row className="flex-grow-1">
          <Col className="col-centered main-content-landing">
            <Row>
              <Col xs={12} sm={4}>
                <div
                  className='centered landing-category-selector'
                  onClick={() => {
                    lib.log_click(null, 'l', 0, 'landing-movies');
                    lib.open_url_in_this_tab(lib.get_url('movies'));
                  }}
                >
                  Movies
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div
                  className='centered landing-category-selector'
                  onClick={() => {
                    lib.log_click(null, 'l', 1, 'landing-books');
                    lib.open_url_in_this_tab(lib.get_url('books'));
                  }}
                >
                  Books
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div
                  className='centered landing-category-selector'
                  onClick={() => {
                    lib.log_click(null, 'l', 2, 'landing-any');
                    lib.open_url_in_this_tab(lib.get_url('any'));
                  }}
                >
                  Anything else
                </div>
              </Col>
            </Row>

            <Row className="spacer" />

            <Row>
              <Col>
                <h3>
                  Some examples to help you get started:
                </h3>
              </Col>
            </Row>

            <Row className="spacer" />

            <Row>
              {
                this.get_categories().splice(0, 3).map(category =>
                  this.renderCategory(category)
                )
              }
            </Row>

            <Row className="spacer" />

            <Row>
              {
                this.get_categories().splice(3, 6).map(category =>
                  this.renderCategory(category)
                )
              }
            </Row>
          </Col>
        </Row>

        <Row className="spacer" />
        <Row className="spacer" />

      </Col>
    )
  }
}

export default Landing;