import React from "react";
import { Row, Col, Container, Form, Card, Button, Alert, } from 'react-bootstrap';
import axios from "axios";
import * as lib from '../lib';
import SmartButton from "./smartButton";
import update from 'react-addons-update';
import Any from './any';

export class BookCard extends React.Component {
  constructor(props) {
    super(props);
  }

  get_metadata(field) {
    if(this.props.metadata && this.props.metadata.volumeInfo && (field in this.props.metadata.volumeInfo))
      return this.props.metadata.volumeInfo[field];
    return null;
  }

  // raw text from gpt3 completion
  title() {
    if(this.props.metadata && this.props.metadata.gpt3_title)
      return this.props.metadata.gpt3_title;
    console.log(this.props.metadata);
    return null;
  }

  // matched title from metadata search
  result_title() {
    var ret = this.get_metadata('title');
    if(ret === null)
      return null;
    if(this.get_metadata('authors'))
      ret += ' by ' + this.get_metadata('authors').join(', ');
    if(this.get_metadata('publishedDate'))
      ret += ' (' + this.get_metadata('publishedDate') + ')';
    return ret;
  }

  thumbnail_link() {
    var tmp = this.get_metadata('imageLinks');
    if(tmp && tmp.thumbnail)
      return tmp.thumbnail;
    return null;
  }

  rec_id() {
    return this.get_metadata('id');
  }

  description_truncated() {
    if(this.get_metadata('description'))
      return this.get_metadata('description').slice(0, 100) + '...';
    return null;
  }

  render() {
    return (
      <Card
        className='movie-card'
        key={this.title()}
        onClick={
          () => {
            lib.log_click(this.props.imp_id, 'r', this.props.index, this.rec_id());
            if(this.get_metadata('infoLink'))
              lib.open_url_in_new_tab(this.get_metadata('infoLink'));
          }
        }
      >
        <Card.Img
          variant = "top"
          src = { this.thumbnail_link() }
        />
        <Card.Body>
          <Card.Title>{this.title()}</Card.Title>
          {
            (this.result_title() === null) ?
            null :
            <Card.Text>
              {this.result_title()}
            </Card.Text>
          }
          <Card.Text>
            { this.description_truncated() }
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
};

export default class Books extends React.Component {
  constructor(props) {
    super(props);

    this.any_ref = React.createRef();
  }

  passed_query() {
    if(this.props.query)
      return this.props.query;
    if(this.props.match && this.props.match.params && this.props.match.params.query)
      return this.props.match.params.query;
    return '';
  }

  render() {
    return (
      <Any
        rec_type = 'books'
        // fetch_metadata = {this.get_book_metadata}
        Item = {BookCard}
        query = {this.passed_query()}
        navbar = {this.props.navbar}
        current_user = {this.props.current_user}
        metadata2uid = {(m) => m['id']}
        metadata2title = {(m) => m['volumeInfo']['title']}
        ref = {this.any_ref}
      />
    );
  }

  fetch_recs() {
    if(this.any_ref.current)
      this.any_ref.current.fetch_recs();
  }
}